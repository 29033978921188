// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-01-introducing-pegasys-index-mdx": () => import("./../src/pages/blog/01-introducing-pegasys/index.mdx" /* webpackChunkName: "component---src-pages-blog-01-introducing-pegasys-index-mdx" */),
  "component---src-pages-blog-02-psys-index-mdx": () => import("./../src/pages/blog/02-psys/index.mdx" /* webpackChunkName: "component---src-pages-blog-02-psys-index-mdx" */),
  "component---src-pages-blog-03-airdrop-index-mdx": () => import("./../src/pages/blog/03-airdrop/index.mdx" /* webpackChunkName: "component---src-pages-blog-03-airdrop-index-mdx" */),
  "component---src-pages-blog-04-pegasys-investors-index-mdx": () => import("./../src/pages/blog/04-pegasys-investors/index.mdx" /* webpackChunkName: "component---src-pages-blog-04-pegasys-investors-index-mdx" */),
  "component---src-pages-blog-05-pegasys-roadmap-index-mdx": () => import("./../src/pages/blog/05-pegasys-roadmap/index.mdx" /* webpackChunkName: "component---src-pages-blog-05-pegasys-roadmap-index-mdx" */),
  "component---src-pages-blog-06-pegasys-staking-index-md": () => import("./../src/pages/blog/06-pegasys-staking/index.md" /* webpackChunkName: "component---src-pages-blog-06-pegasys-staking-index-md" */),
  "component---src-pages-blog-07-pegasys-v-3-index-mdx": () => import("./../src/pages/blog/07-pegasys-v3/index.mdx" /* webpackChunkName: "component---src-pages-blog-07-pegasys-v-3-index-mdx" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bug-bounty-basis-mdx": () => import("./../src/pages/bug-bounty-basis.mdx" /* webpackChunkName: "component---src-pages-bug-bounty-basis-mdx" */),
  "component---src-pages-bug-bounty-mdx": () => import("./../src/pages/bug-bounty.mdx" /* webpackChunkName: "component---src-pages-bug-bounty-mdx" */),
  "component---src-pages-confirm-js": () => import("./../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-disclaimer-mdx": () => import("./../src/pages/disclaimer.mdx" /* webpackChunkName: "component---src-pages-disclaimer-mdx" */),
  "component---src-pages-faq-index-md": () => import("./../src/pages/faq/index.md" /* webpackChunkName: "component---src-pages-faq-index-md" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-of-service-mdx": () => import("./../src/pages/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-terms-of-service-mdx" */),
  "component---src-pages-tips-index-md": () => import("./../src/pages/tips/index.md" /* webpackChunkName: "component---src-pages-tips-index-md" */),
  "component---src-pages-trademarks-mdx": () => import("./../src/pages/trademarks.mdx" /* webpackChunkName: "component---src-pages-trademarks-mdx" */)
}

